<template>
  <form @submit.prevent="createContract()">
    <h4 class="mb-2">
      Приложение №1 Заявление о присоединении, а также на изготовление ключей и
      регистрацию регистрационного свидетельства.
    </h4>
    <hr class="line__hr" />

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Наименование юридического лица
              </div>
              <div class="agreement__item--title" v-else>
                Наименование юридического лица (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-model="uc_kcmr.client_name_company"
                  v-if="!lang_type"
                  name=""
                  placeholder="Наименование юридического лица"
                  value=""
                />
                <input
                  type="text"
                  required
                  v-model="uc_kcmr.client_name_company_kz"
                  v-else
                  name=""
                  placeholder="Наименование юридического лица (на казахском)"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">БИН / ИИН / VATIN</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-model="uc_kcmr.client_bin_iin_vatin"
                  name=""
                  placeholder="БИН / ИИН / VATIN"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Свидетельство о регистрации(номер)</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-model="uc_kcmr.certificate_number"
                  name=""
                  placeholder="номер"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Свидетельство о регистрации(дата выдачи)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="date"
                  @input="selectedDate(0)"
                  v-model="uc_kcmr.certificate_date"
                  name=""
                  placeholder="дата выдачи"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Свидетельство о регистрации(выдавший орган)
              </div>
              <div class="agreement__item--title" v-else>
                Свидетельство о регистрации(выдавший орган) (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-model="uc_kcmr.certificate_given_by"
                  name=""
                  placeholder="Свидетельство о регистрации(выдавший орган)"
                  value=""
                  v-if="!lang_type"
                />
                <input
                  type="text"
                  required
                  v-model="uc_kcmr.certificate_given_by_kz"
                  name=""
                  placeholder=" Свидетельство о регистрации(выдавший орган) (на казахском)"
                  value=""
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Действующего на основании
              </div>
              <div class="agreement__item--title" v-else>
                Действующего на основании (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-model="uc_kcmr.client_based_on"
                  v-if="!lang_type"
                  name=""
                  placeholder="Действующего на основании"
                  value=""
                />
                <input
                  type="text"
                  required
                  v-model="uc_kcmr.client_based_on_kz"
                  v-else
                  name=""
                  placeholder="Действующего на основании (на казахском)"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Просит изготовить на имя уполномоченного лица
              </div>
            </div>
            <div class="col-lg-9">
              <v-select
                :items="users"
                :hide-details="true"
                :flat="true"
                item-text="common_name"
                item-value="id"
                dense
                outlined
                v-model="user"
                class="mb-5"
                label="Выберите уполномоченного лица"
                @change="selectedUser"
              ></v-select>

              <div class="agreement__item--input mb-4">
                <input
                  type="text"
                  required
                  name=""
                  v-model="uc_kcmr.client_want_create_for_fio"
                  placeholder="ФИО удостоверения личности /паспорта"
                  value=""
                />
                <em>ФИО удостоверения личности /паспорта</em>
              </div>

              <div class="agreement__item--input mb-4">
                <input
                  type="text"
                  required
                  name=""
                  v-model="uc_kcmr.client_want_create_for_seria"
                  placeholder="серия  удостоверения личности /паспорта"
                  value=""
                />
                <em>серия удостоверения личности /паспорта</em>
              </div>

              <div class="agreement__item--input mb-4">
                <input
                  type="text"
                  required
                  name=""
                  v-model="uc_kcmr.client_want_create_for_number"
                  placeholder="номер   удостоверения личности /паспорта"
                  value=""
                />
                <em>номер удостоверения личности /паспорта</em>
              </div>

              <div class="agreement__item--input mb-4" v-if="!lang_type">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Кем выдан"
                  v-model="uc_kcmr.client_want_create_for_given"
                  value=""
                />
                <em>Кем выдан</em>
              </div>

              <div class="agreement__item--input mb-4" v-else>
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Кем выдан (на казахском)"
                  v-model="uc_kcmr.client_want_create_for_given_kz"
                  value=""
                />
                <em>Кем выдан (на казахском)</em>
              </div>

              <div class="agreement__item--input mb-4">
                <input
                  type="date"
                  @input="selectedDate(1)"
                  v-model="uc_kcmr.client_want_create_for_when_given"
                  placeholder=""
                  value=""
                />
                <em>Когда выдан</em>
              </div>

              <div class="agreement__item--input mb-4">
                <input
                  type="text"
                  required
                  name=""
                  placeholder=""
                  v-model="uc_kcmr.client_want_create_for_iin"
                  value=""
                />
                <em>ИИН</em>
              </div>

              <div class="agreement__item--input" v-if="!lang_type">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Место жительства"
                  v-model="uc_kcmr.client_want_create_for_live_address"
                  value=""
                />
                <em>Место жительства</em>
              </div>

              <div class="agreement__item--input" v-else>
                <input
                  type="text"
                  required
                  name=""
                  v-model="uc_kcmr.client_want_create_for_live_address_kz"
                  placeholder="Место жительства (на казахском)"
                  value=""
                />
                <em>Место жительства (на казахском)</em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Необходимо выбрать соответствующие пункты
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--list">
                <ol class="form__login--check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        v-model="uc_kcmr.client_has_crypt_key"
                        name="key"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">Криптографические ключи</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        v-model="uc_kcmr.client_has_pin_convert"
                        name="key"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">ПИН-конверт,</div>
                    </label>
                  </li>
                </ol>
              </div>
            </div>

            <br />
            <br />
            <br />

            <div class="col-lg-9 offset-lg-3" v-if="uc_kcmr.client_has_pin_convert">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-model="uc_kcmr.client_pin_convert_number"
                  name=""
                  placeholder="идентификатор ПИН-конверта"
                  value=""
                />
                <em
                  >(идентификатор ПИН-конверта, предоставляется Удостоверяющим центром,если выбран пункт 2)</em
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Создать и зарегистрировать в регистре регистрационных свидетельств
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--list">
                <ol class="form__login--check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        @change="checkFirstAreaClicked(1)"
                        v-model="uc_kcmr.client_has_message"
                        :value="uc_kcmr.client_has_message"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">
                        система обмена банковскими сообщениями;
                      </div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        @change="checkFirstAreaClicked(2)"
                        v-model="uc_kcmr.client_has_transaction"
                        :value="uc_kcmr.client_has_transaction"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">
                        межбанковская система перевода денег;
                      </div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        @change="checkFirstAreaClicked(3)"
                        v-model="uc_kcmr.client_has_clean"
                        :value="uc_kcmr.client_has_clean"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">межбанковский клиринг;</div>
                    </label>
                  </li>

                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        @change="checkSecondAreaClicked(1)"
                        v-model="uc_kcmr.client_has_avangard"
                        :value="uc_kcmr.client_has_avangard"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">
                        система розничных платежей Авангард Плат;
                      </div>
                    </label>
                  </li>

                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        @change="checkSecondAreaClicked(2)"
                        v-model="uc_kcmr.client_has_many_transaction"
                        :value="uc_kcmr.client_has_many_transaction"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">
                        система массовых электронных платежей;
                      </div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        @change="checkSecondAreaClicked(3)"
                        v-model="uc_kcmr.client_has_fasti"
                        :value="uc_kcmr.client_has_fasti"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">ФАСТИ;</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        @change="checkSecondAreaClicked(4)"
                        v-model="uc_kcmr.client_has_swift"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">сервисное Бюро SWIFT;</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        @change="checkSecondAreaClicked(5)"
                        v-model="uc_kcmr.client_has_two_factor_swift"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">
                        двухфакторная аутентификация пользователей сервисного бюро SWIFT;
                      </div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        @change="checkSecondAreaClicked(6)"
                        v-model="uc_kcmr.client_has_moment_payment"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">
                        система моментальных платежей;
                      </div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        @change="checkSecondAreaClicked(7)"
                        v-model="uc_kcmr.client_has_id_transaction"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">
                        центр обмена идентификационными данными
                      </div>
                    </label>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="line__hr" />

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="agreement__item--title">
                Идентификационные данные для криптографических ключей
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Фамилия, Имя, Отчество</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  v-model="uc_kcmr.client_crypt_fio"
                  placeholder="Фамилия, Имя, Отчество"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Имя субъекта, предоставляет Удостоверяющий центр (CN name до 64 символов)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  v-model="uc_kcmr.client_crypt_cn"
                  placeholder="Имя субъекта"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Наименование страны
              </div>
              <div class="agreement__item--title" v-else>
                Наименование страны (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Наименование страны"
                  v-model="uc_kcmr.client_crypt_country"
                  value=""
                  v-if="!lang_type"
                />
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Наименование страны (на казахском)"
                  v-model="uc_kcmr.client_crypt_country_kz"
                  value=""
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Наименование места работы
              </div>
              <div class="agreement__item--title" v-else>
                Наименование места работы (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Наименование места работы"
                  v-model="uc_kcmr.client_crypt_place_work"
                  v-if="!lang_type"
                />
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Наименование места работы  (на казахском)"
                  v-model="uc_kcmr.client_crypt_place_work_kz"
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Наименование должности
              </div>
              <div class="agreement__item--title" v-else>
                Наименование должности (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Наименование должности"
                  value=""
                  v-model="uc_kcmr.client_crypt_position"
                  v-if="!lang_type"
                />
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Наименование должности (на казахском)"
                  v-model="uc_kcmr.client_crypt_position_kz"
                  value=""
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                ИИН (или номер паспорта для нерезидентов)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="ИИН"
                  v-model="uc_kcmr.client_crypt_iin"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Адрес электронной почты</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="email"
                  required
                  name=""
                  v-model="uc_kcmr.client_crypt_email"
                  placeholder="Адрес электронной почты"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Области использования регистрационного свидетельства
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--list">
                <ol class="form__login--check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        v-model="uc_kcmr.client_has_first_initial"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">Первичная инициализация</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        v-model="uc_kcmr.client_has_ecp"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">
                        Электронная цифровая подпись, доказательность
                      </div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        v-model="uc_kcmr.client_has_auth"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">Аутентификация клиента</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        v-model="uc_kcmr.client_has_auth_domain"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">
                        Аутентификация организации или домена
                      </div>
                    </label>
                  </li>

                  <li>
                    <label>
                      <input
                        type="checkbox"
                        class="mr-2"
                        v-model="uc_kcmr.client_has_secret"
                        name="2"
                      />
                      <!-- <div class="form__checkbox--check"></div> -->
                      <div class="form__checkbox--text">
                        Обеспечение конфиденциальности
                      </div>
                    </label>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Фамилия, Имя, Отчество уполномоченного сотрудника
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="Фамилия, Имя, Отчество"
                  v-model="uc_kcmr.client_fio"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">№ удостоверения личности/паспорта</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  name=""
                  placeholder="№ удостоверения личности/паспорта"
                  v-model="uc_kcmr.client_number"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Когда выдан удостоверения личности/паспорт
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="date"
                  @input="selectedDate(2)"
                  name=""
                  v-model="uc_kcmr.client_when_given"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Кем выдан личности/паспорт
              </div>
              <div class="agreement__item--title" v-else>
                Кем выдан личности/паспорт (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input" v-if="!lang_type">
                <input
                  type="text"
                  required
                  name=""
                  v-model="uc_kcmr.client_given_country"
                  placeholder="Кем выдан личности/паспорт"
                  value=""
                />
              </div>
              <div class="agreement__item--input" v-else>
                <input
                  type="text"
                  required
                  name=""
                  v-model="uc_kcmr.client_given_country_kz"
                  placeholder="Кем выдан личности/паспорт (на казахском)"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="line__hr" />

    <div class="row">
      <div class="col-lg-9 offset-lg-3">
        <button type="submit" class="form__button">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z"
              fill="white"
            ></path>
          </svg>
          Отправить
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["lang_type"],
  components: {},
  data() {
    return {
      document: "",
      uc_kcmr: {
        client_signer_in: "",
        client_signer_in_kz: "",
        client_fio: "",
        client_fio_kz: "",
        client_based_on: "Устава",
        client_based_on_kz: "Жарғыдан",
        client_phone_number: "",
        client_bin: "",
        client_bin_iin_vatin: "",
        client_name_company: "",
        client_name_company_kz: "",
        client_has_avangard: false,
        client_has_many_transaction: false,
        client_has_fasti: false,
        client_has_swift: false,
        client_has_two_factor_swift: false,
        client_has_moment_payment: false,
        client_has_id_transaction: false,
        client_has_message: false,
        client_has_transaction: false,
        client_has_clean: false,
      },
      images: [],
      checkboxes: [false, false, false, false, false, false, false],
      users: [],
      user: "",
    };
  },
  created() {
    this.connection = new WebSocket("wss://127.0.0.1:13579/");
    this.connection.onopen = () => {
      this.ready = true;
    };
    this.connection.onmessage = (e) => {
      const arr = JSON.parse(e.data);
      const parse__arr = arr;
      this.esp__array = parse__arr;
    };
  },

  methods: {
    getAttachment() {
      this.$axios
        .get(
          this.$API_URL +
            this.$API_VERSION +
            "user/attachment?id=" +
            this.$route.params.id,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.user = response.data.responsible_id;
          this.uc_kcmr = response.data.fields;
          this.document_id = response.data.document_id;
          if (response.data.fields) {
            this.setValuesCheckbox();
          }
        })
        .catch(() => {
  

          this.$toast.open({
            message: "Заполните все поля на русском и казахском",
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
        });
    },
    setValuesCheckbox() {
      this.uc_kcmr.client_has_avangard == "false"
        ? (this.uc_kcmr.client_has_avangard = false)
        : (this.uc_kcmr.client_has_avangard = true);
      this.uc_kcmr.client_has_many_transaction == "false"
        ? (this.uc_kcmr.client_has_many_transaction = false)
        : (this.uc_kcmr.client_has_many_transaction = true);
      this.uc_kcmr.client_has_fasti == "false"
        ? (this.uc_kcmr.client_has_fasti = false)
        : (this.uc_kcmr.client_has_fasti = true);
      this.uc_kcmr.client_has_swift == "false"
        ? (this.uc_kcmr.client_has_swift = false)
        : (this.uc_kcmr.client_has_swift = true);
      this.uc_kcmr.client_has_two_factor_swift == "false"
        ? (this.uc_kcmr.client_has_two_factor_swift = false)
        : (this.uc_kcmr.client_has_two_factor_swift = true);
      this.uc_kcmr.client_has_moment_payment == "false"
        ? (this.uc_kcmr.client_has_moment_payment = false)
        : (this.uc_kcmr.client_has_moment_payment = true);
      this.uc_kcmr.client_has_id_transaction == "false"
        ? (this.uc_kcmr.client_has_id_transaction = false)
        : (this.uc_kcmr.client_has_id_transaction = true);
    },

    checkFirstAreaClicked(index) {
      this.clearSecondArea();
      if (index == 1) {
        this.uc_kcmr.client_has_message = true;
        // this.uc_kcmr.client_has_transaction = false;
        // this.uc_kcmr.client_has_clean = false;
      } else if (index == 2) {
        // this.uc_kcmr.client_has_message = false;
        this.uc_kcmr.client_has_transaction = true;
        // this.uc_kcmr.client_has_clean = false;
      } else if (index == 3) {
        // this.uc_kcmr.client_has_message = false;
        // this.uc_kcmr.client_has_transaction = false;
        this.uc_kcmr.client_has_clean = true;
      }
    },
    checkSecondAreaClicked(index) {
      this.clearFirstArea();
      if (index == 1) {
        this.uc_kcmr.client_has_avangard = true;
        this.uc_kcmr.client_has_many_transaction = false;
        this.uc_kcmr.client_has_fasti = false;
        this.uc_kcmr.client_has_swift = false;
        this.uc_kcmr.client_has_two_factor_swift = false;
        this.uc_kcmr.client_has_moment_payment = false;
        this.uc_kcmr.client_has_id_transaction = false;
      } else if (index == 2) {
        this.uc_kcmr.client_has_avangard = false;
        this.uc_kcmr.client_has_many_transaction = true;
        this.uc_kcmr.client_has_fasti = false;
        this.uc_kcmr.client_has_swift = false;
        this.uc_kcmr.client_has_two_factor_swift = false;
        this.uc_kcmr.client_has_moment_payment = false;
        this.uc_kcmr.client_has_id_transaction = false;
      } else if (index == 3) {
        this.uc_kcmr.client_has_avangard = false;
        this.uc_kcmr.client_has_many_transaction = false;
        this.uc_kcmr.client_has_fasti = true;
        this.uc_kcmr.client_has_swift = false;
        this.uc_kcmr.client_has_two_factor_swift = false;
        this.uc_kcmr.client_has_moment_payment = false;
        this.uc_kcmr.client_has_id_transaction = false;
      } else if (index == 4) {
        this.uc_kcmr.client_has_avangard = false;
        this.uc_kcmr.client_has_many_transaction = false;
        this.uc_kcmr.client_has_fasti = false;
        this.uc_kcmr.client_has_swift = true;
        this.uc_kcmr.client_has_two_factor_swift = false;
        this.uc_kcmr.client_has_moment_payment = false;
        this.uc_kcmr.client_has_id_transaction = false;
      } else if (index == 5) {
        this.uc_kcmr.client_has_avangard = false;
        this.uc_kcmr.client_has_many_transaction = false;
        this.uc_kcmr.client_has_fasti = false;
        this.uc_kcmr.client_has_swift = false;
        this.uc_kcmr.client_has_two_factor_swift = true;
        this.uc_kcmr.client_has_moment_payment = false;
        this.uc_kcmr.client_has_id_transaction = false;
      } else if (index == 6) {
        this.uc_kcmr.client_has_avangard = false;
        this.uc_kcmr.client_has_many_transaction = false;
        this.uc_kcmr.client_has_fasti = false;
        this.uc_kcmr.client_has_swift = false;
        this.uc_kcmr.client_has_two_factor_swift = false;
        this.uc_kcmr.client_has_moment_payment = true;
        this.uc_kcmr.client_has_id_transaction = false;
      } else if (index == 7) {
        this.uc_kcmr.client_has_avangard = false;
        this.uc_kcmr.client_has_many_transaction = false;
        this.uc_kcmr.client_has_fasti = false;
        this.uc_kcmr.client_has_swift = false;
        this.uc_kcmr.client_has_two_factor_swift = false;
        this.uc_kcmr.client_has_moment_payment = false;
        this.uc_kcmr.client_has_id_transaction = true;
      }
    },
    clearFirstArea() {
      this.uc_kcmr.client_has_message = false;
      this.uc_kcmr.client_has_transaction = false;
      this.uc_kcmr.client_has_clean = false;
    },
    clearSecondArea() {
      this.uc_kcmr.client_has_avangard = false;
      this.uc_kcmr.client_has_many_transaction = false;
      this.uc_kcmr.client_has_fasti = false;
      this.uc_kcmr.client_has_swift = false;
      this.uc_kcmr.client_has_two_factor_swift = false;
      this.uc_kcmr.client_has_moment_payment = false;
      this.uc_kcmr.client_has_id_transaction = false;
    },

    printError() {
      this.$toast.open({
        message: "Неверная дата",
        type: "error",
        position: "bottom",
        duration: 4000,
        queue: true,
      });
    },
    selectedDate(index) {
      if (index == 0) {
        if (this.uc_kcmr.certificate_date.split("-")[0] > new Date().getFullYear()) {
          this.uc_kcmr.certificate_date = "";
          this.printError();
        }
      }
      if (index == 1) {
        if (
          this.uc_kcmr.client_want_create_for_when_given.split("-")[0] >
          new Date().getFullYear()
        ) {
          this.uc_kcmr.client_want_create_for_when_given = "";
          this.printError();
        }
      }
      if (index == 2) {
        if (this.uc_kcmr.client_when_given.split("-")[0] > new Date().getFullYear()) {
          this.uc_kcmr.client_when_given = "";
          this.printError();
        }
      }
    },
    selectedUser() {
      let filtered_user = this.users.filter((item) => item.id == this.user)[0];
      console.log(filtered_user);
      this.uc_kcmr.client_want_create_for_fio =
        filtered_user.common_name + " " + filtered_user.last_name;
      this.uc_kcmr.client_want_create_for_iin = filtered_user.iin;
    },
    getUsers() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "user/organization/users",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.users = response.data.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    uploadDocument(e) {
      const file = e.target.files;
      for (var i = 0; i < file.length; i++) {
        this.images.push(file[i]);
      }
    },
    getProfile() {
      this.uc_kcmr.client_name_company = this.GET_USER_DATA.organization.name;
      this.uc_kcmr.client_name_company_kz = this.GET_USER_DATA.organization.name;
      this.uc_kcmr.client_bin = this.GET_USER_DATA.organization.bin;
      this.uc_kcmr.client_phone_number = this.GET_USER_DATA.organization.phone;
      this.uc_kcmr.client_fio =
        this.GET_USER_DATA.common_name + " " + this.GET_USER_DATA.last_name;
      this.uc_kcmr.client_fio_kz =
        this.GET_USER_DATA.common_name + " " + this.GET_USER_DATA.last_name;
      this.uc_kcmr.client_signer_in = this.GET_USER_DATA.role.name_ru;
      this.uc_kcmr.client_signer_in_kz = this.GET_USER_DATA.role.name_kz;
      this.uc_kcmr.client_last_name = this.GET_USER_DATA.last_name;
      this.uc_kcmr.client_last_name_kz = this.GET_USER_DATA.last_name;
      this.uc_kcmr.client_bik = this.GET_USER_DATA.organization.bik;

      this.uc_kcmr.client_bin_iin_vatin = this.GET_USER_DATA.organization.bin;
    },

    createContract() {
      let contractForm = new FormData();
      for (var key in this.uc_kcmr) {
        contractForm.append("attachments[attachment_1][" + key + "]", this.uc_kcmr[key]);
      }
      for (var i = 0; i < this.images.length; i++) {
        contractForm.append("documents[]", this.images[i]);
      }
      contractForm.append("responsible_id", this.user);
      contractForm.append("type", "attachment_1");
      if (this.$route.params.id) {
        contractForm.append("attachment_id", this.$route.params.id);
      }

      this.$axios
        .post(this.$API_URL + this.$API_VERSION + "user/documents/uckcmr", contractForm, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data) {
            this.$toast.open({
              message: "Успешно заполнено",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
          }
          this.$router.push("/");
        })
        .catch((error) => {
					if (error.response.data.message) {
						this.$toast.open({
              message: error.response.data.message,
              type: "error",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
					}
          this.$toast.open({
            message: "Заполните все поля на русском и казахском",
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
        });
    },
    getTypes() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "types",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.document.types = response.data.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
  mounted() {
    this.getUsers();
    this.getTypes();
    this.getProfile();

    if (this.$route.query.type == "update_uckcmr") {
      this.getAttachment();
    }
  },
  watch: {},
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
};
</script>
