<template>
  <div class="wrapper">
    <div class="container">
      <div class="section__line pointer">
        <div class="link__top">
          <a class="link__back pointer" @click="$router.push('/')">
            <span class="link__back--img">
              <img src="../../assets/img/arrow-back.svg" />
            </span>
            Назад
          </a>
        </div>
      </div>

      <div class="section__line">
        <div class="section__bg section--bank__info section--bank__data">
          <div class="section__title--block section__title--margin">
            <div class="item__column">
              <div class="section__title">Создание внешного типового договора</div>

              <div class="item__row item__ac agreement__lang mt-4">
                <div
                  class="agreement__lang__type item__abs mr-2 pointer"
                  @click="langRu = true"
                  v-bind:class="{ agreement__lang__active: langRu == true }"
                >
                  RU
                </div>
                <div
                  class="agreement__lang__type item__abs pointer"
                  @click="langRu = false"
                  v-bind:class="{ agreement__lang__active: langRu == false }"
                >
                  KZ
                </div>
              </div>
            </div>

            <div class="type__contract">
              <div class="type__contract--img">
                <img src="../../assets/img/type__contract.svg" />
              </div>
              <div class="type__contract--info">
                <div class="type__contract--type">Тип договора</div>
                <div class="type__contract--name" v-if="document.type">
                  {{ document.type.title }}
                </div>
              </div>
            </div>
          </div>

          <div class="agreement__info--block">
            <div class="agreement__info--item">
              <div class="agreement__info--icon">
                <img src="../../assets/img/file-icon.svg" />
              </div>
              <div class="agreement__info--right">
                <div class="agreement__info--type">Вид договора</div>
                <div class="agreement__info--title">Внешний</div>
              </div>
            </div>
          </div>

          <DocType
            :document="document"
            :contract_title="contract_title"
            :lang_type="!langRu"
          />
          <CreateSobsForm v-if="document.type.name == 'sobs'" :lang_type="!langRu" />
          <CreateUcFirstAttachment
            :fields="fields"
            v-if="document.type.name == 'uc'"
            :lang_type="!langRu"
          />
          <FastiCreateForm
            v-if="document.type.name == 'fasti'"
            :lang_type="!langRu"
            :contract_title="contract_title"
          />
          <SmepCreateForm v-if="document.type.name == 'smep'" :lang_type="!langRu" />
          <MspdCreateForm
            v-if="document.type.name == 'mspd'"
            :lang_type="!langRu"
            :contract_title="contract_title"
            :messages="messages"
          />
          <VisaCreateForm v-if="document.type.name == 'visa'" :lang_type="!langRu" />
          <CreateCoidSimpleForm
            v-if="document.type.name == 'coid_simple_license'"
            :lang_type="!langRu"
          />
          <CreateCoidNotBankForm
            v-if="document.type.name == 'coid_notbank'"
            :lang_type="!langRu"
          />
          <CreateCoidService
            v-if="document.type.name == 'coid_service'"
            :lang_type="!langRu"
          />
          <MastercardCreateForm
            v-if="document.type.name == 'mastercard'"
            :lang_type="!langRu"
          />
          <CreateSmkForm v-if="document.type.name == 'smk'" :lang_type="!langRu" />
          <CreateUcKcmrForm
            v-if="
              document.type.name == 'uckcmr' ||
              ($route.query.type && $route.query.type == 'update_uckcmr') ||
              $route.query.type == 'create_uckcmr'
            "
            :lang_type="!langRu"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateUcFirstAttachment from "./uc/attachment-1";
import DocType from "./DocType";
import FastiCreateForm from "./fasti/CreateFastiFirstAttachment";
import MspdCreateForm from "./mspd/CreateMspdContract";
import VisaCreateForm from "./visa/VisaCreateForm";
import CreateSmkForm from "./smk/CreateSmkForm";
import SmepCreateForm from "./smep/SmepCreateForm";
import MastercardCreateForm from "./mastercard/MastercardCreateForm";
import CreateCoidSimpleForm from "./coid_simple/CreateCoidSimpleForm";
import CreateUcKcmrForm from "./uckcmr/CreateUcKcmrForm";
import CreateCoidNotBankForm from "./coid_not_bank/CreateCoidNotBankForm";
import CreateCoidService from "./coid_service/CreateCoidService";
import CreateSobsForm from "./sobs/CreateSobsForm";
export default {
  components: {
    DocType,
    FastiCreateForm,
    MspdCreateForm,
    VisaCreateForm,
    MastercardCreateForm,
    SmepCreateForm,
    CreateCoidSimpleForm,
    CreateCoidNotBankForm,
    CreateCoidService,
    CreateSmkForm,
    CreateUcKcmrForm,
    CreateUcFirstAttachment,
    CreateSobsForm,
  },
  data() {
    return {
      langRu: true,
      contract_title: {
        contract_full_name: "",
        contract_full_name_kz: "",
      },
      document: {
        id: "",
        file_path: "",
        types: "",
        type: "Выберите тип договора",
      },
      contract: {
        type: "",
      },
      images: [],
      messages: null,
      fields: "",
    };
  },
  methods: {
    getTypes() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "types",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.document.types = response.data.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
  mounted() {
    this.getTypes();
  },
};
</script>
