<template>
<div>
                        <div class="agreement__line">
                            <div class="row">
                                <div class="col-lg-6">

                                    <div class="row align-items-center">
                                    
                                        <div class="col-lg-6">
                                            <div class="agreement__item--title">
                                                Тип договора
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="agreement__item--input">
                                                <select name="" v-model="document.type" @change="selectedDocType">
                                                    <option value="Выберите тип договора" disabled selected>Выберите тип договора</option>
                                                    <option  v-for="item in document.types" :value="item" :key="item.id" >{{item.title}}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="agreement__line" v-if="nameContract[0].lang_kz!=''">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="row align-items-center">

                                        <div class="col-lg-3" v-if="lang_type==0">
                                            <div class="agreement__item--title">
                                                Название договора
                                            </div>
                                        </div>
                                        <div class="col-lg-3" v-if="lang_type==1">
                                            <div class="agreement__item--title">
                                                Название договора (на казахском)
                                            </div>
                                        </div>
                                        <div class="col-lg-9">
                                            <div class="agreement__item--input" v-if="lang_type==1">
                                                <input type="text" required v-model="nameContract[0].lang_kz" placeholder="Название договора (на казахском)" value="" disabled>
                                            </div>
                                            <div class="agreement__item--input" v-if="lang_type==0">
                                                <input type="text" required v-model="nameContract[0].lang_ru" placeholder="Название договора (на казахском)" value="" disabled>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>



</div>
</template>

<script>
export default {
    components: {
      
    },
    props: ['document','lang_type','contract_title'],
    data () {
        return {
                nameContract: [{
                    lang_kz: '',
                    lang_ru: ''
                }],
               nameContracts: [
                {
                    type: 'sobs',
                    lang_kz: 'Банк хабарларымен алмасу жүйесінде (БХАЖ) қызметтер көрсету туралы шарт',
                    lang_ru:'Договор о предоставлении услуг в системе обмена банковскими сообщениями (СОБС)'
                },
                
                {
                    type:'mspd',
                    lang_ru: 'Договор об оказании услуг в межбанковской системе  переводов денег ',
                    lang_kz: 'Банкаралық ақша аудару жүйесінде қызметтер көрсету туралы шарт'
                },
                 {
                    type:'fasti',
                    lang_ru: 'Договор (присоединения) на предоставление услуг финансовой автоматизированной системы транспорта информации (ФАСТИ)',
                    lang_kz: 'Ақпаратты тасымалдаудың  қаржылық автоматтандырылған жүйесі  (АТҚАЖ) қызметтерін көрсету (қосылу)  шарты'
                },
                {
                    type: 'smep',
                    lang_kz: 'Жаппай электрондық төлемдер жүйесіне  (ЖЭТЖ) қатысу және  қызметтер көрсету туралы шарт',
                    lang_ru: 'ДОГОВОР об участии и оказании услуг в системе массовых электронных платежей (СМЭП)'
                },
                {
                    type: 'visa',
                    lang_kz: 'Ұлттық  ралық крилинг (KAZNNSS) нәтижелері бойынша VISA төлем жүйесі ұсынған   ралық ақша аудару жүйесінде қызмет көрсетуді ұсыну туралы',
                    lang_ru: 'ДОГОВОР о предоставлении услуг в межбанковской системе переводов денег по представленным платежной системой VISA результатам национального межбанковского клиринга (KAZNNSS)'
                },
                {
                    type: 'mastercard',
                    lang_kz: 'Ұлттық банкаралық клиринг (KAZNNSS) нәтижелері бойынша MasterCard төлем жүйесі ұсынған банкаралық ақша аудару жүйесінде қызмет көрсетуді ұсыну туралы',
                    lang_ru: 'ДОГОВОР о предоставлении услуг в межбанковской системе переводов денег по представленным платежной системой MasterCard результатам национального межбанковского клиринга (KAZNNSS)'
                },
                {
                    type: 'coid_simple_license',
                    lang_kz: 'СДАО АЖ үшін БҚ  жай ғана айрықша емес лицензиясын беру туралы (қосылу) шарты',
                    lang_ru: 'ДОГОВОР (присоединения) о предоставлении простой неисключительной лицензии ПО для ИС ЦОИД'
                },
                {
                    type: 'coid_notbank',
                    lang_kz: 'Банктік емес ұйымдарға арналған   «Сәйкестендіру деректерімен алмасу орталығы»  ақпараттық жүйесінің  қызметтерін көрсету туралы  (қосылу) ШАРТЫ',
                    lang_ru: 'ДОГОВОР (присоединения) о предоставлении услуг в информационной системе «Центр обмена идентификационными данными» для небанковских организаций'
                },
                {
                    type: 'coid_service',
                    lang_kz: 'Сәйкестендіру деректерімен алмасу орталығы» ақпараттық жүйесінің  қызметтерін көрсету туралы (қосылу) ШАРТЫ',
                    lang_ru: 'ДОГОВОР (присоединения) о предоставлении услуг в информационной системе «Центр обмена идентификационными данными»'
                },
                {
                    type: 'smk',
                    lang_kz: 'Банкаралық клиринг жүйесінде қызметтер көрсету туралы шарт',
                    lang_ru: 'Договор об оказании услуг в системе  межбанковского клиринга',
                },
                {
                    type: 'uckcmr',
                    lang_kz: 'ҚБЕО жүйелерінде куәландырушы орталықтың қызметтерін ұсыну туралы ШАРТ',
                    lang_ru: 'ДОГОВОР о предоставлении услуг удостоверяющего центра в системах КЦМР',
                },
                {
                    type: 'uc',
                    lang_kz: 'Сертификаттау орталығының қызметтерін көрсету туралы КЕЛІСІМ',
                    lang_ru: 'ДОГОВОР  о предоставлении услуг Удостоверяющего центра ',
                }

                



            ]
        
        
        }
    },
    methods: {
        selectedDocType() {
           this.nameContract = this.filterLabel(this.document.type.name);

           this.contract_title.contract_full_name =   this.nameContract[0].lang_ru;
           this.contract_title.contract_full_name_kz =   this.nameContract[0].lang_kz;
        },
        filterLabel(type) {
            return this.nameContracts.filter(el => el.type == type);
        },
    },
    mounted() {
       
    },
    watch: {
       
    }
}
</script>
