<template>
  <form @submit.prevent="createContract()">
    <hr class="line__hr" />

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                ФИО первого руководителя
              </div>
              <div class="agreement__item--title" v-else>
                ФИО первого руководителя (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  v-model="coid_service.client_fio"
                  name=""
                  placeholder="Фамилия, инициалы подписанта"
                  v-if="!lang_type"
                />
                <input
                  type="text" required
                  v-model="coid_service.client_fio_kz"
                  name=""
                  placeholder="Фамилия, инициалы подписанта"
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Наименование полное
              </div>
              <div class="agreement__item--title" v-else>
                Наименование полное (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_name_company"
                  placeholder="Наименование полное"
                  v-if="!lang_type"
                />
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_name_company_kz"
                  placeholder="Наименование полное (на казахском)"
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Наименование сокращенное
              </div>
              <div class="agreement__item--title" v-else>
                Наименование сокращенное (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_name_company_short"
                  placeholder="Наименование сокращенное"
                  value=""
                  v-if="!lang_type"
                />
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_name_company_short_kz"
                  placeholder="Наименование сокращенное (на казахском)"
                  value=""
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Юридический адрес
              </div>
              <div class="agreement__item--title" v-else>
                Юридический адрес (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_address"
                  placeholder="Юридический адрес"
                  v-if="!lang_type"
                  value=""
                />
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_address_kz"
                  placeholder="Юридический адрес (на казахском)"
                  v-else
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Фактический адрес
              </div>
              <div class="agreement__item--title" v-else>
                Фактический адрес (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_fact_address"
                  placeholder="Фактический адрес"
                  v-if="!lang_type"
                  value=""
                />
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_fact_address_kz"
                  placeholder="Фактический адрес (на казахском)"
                  v-else
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Почтовый индекс</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_post_index"
                  placeholder="Почтовый индекс"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="lang_type">
                ФИО первичного контакта
              </div>
              <div class="agreement__item--title" v-else>ФИО первичного контакта</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-if="lang_type"
                  v-model="coid_service.client_first_fio"
                  placeholder="ФИО  первичного контакта"
                  value=""
                />
                <input
                  type="text" required
                  name=""
                  v-else
                  v-model="coid_service.client_first_fio_kz"
                  placeholder="ФИО  первичного контакта"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Телефон первичного контакта</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
								<input type="text" required v-mask="{mask: '+7 (999) 999 99 99'}"  v-model="coid_service.client_phone_number" placeholder="Телефон первичного контакта"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Факс первичного контакта</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
								<input type="text" required v-mask="{mask: '+7 (999) 999 99 99'}"  v-model="coid_service.client_fax_first" placeholder="Телефон первичного контакта"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">E-mail первичного контакта</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="email" required
                  v-model="coid_service.client_first_email"
                  name=""
                  placeholder="E-mail первичного контакта"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="lang_type">ФИО бухгалтера</div>
              <div class="agreement__item--title" v-else>ФИО бухгалтера</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  v-model="coid_service.client_fio_account"
                  v-if="lang_type"
                  name=""
                  placeholder="ФИО бухгалтера"
                  value=""
                />
                <input
                  type="text" required
                  v-model="coid_service.client_fio_account_kz"
                  v-else
                  name=""
                  placeholder="ФИО бухгалтера"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Телефон бухгалтера</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_phone_number_second"
                  placeholder="Контактные данные бухгалтера"
                  value=""
									v-mask="{mask: '+7 (999) 999 99 99'}"
                />
					
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Факс бухгалтера</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_fax_second"
                  placeholder="Контактные данные бухгалтера"
                  value=""
									v-mask="{mask: '+7 (999) 999 99 99'}"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">E-mail бухгалтера</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_email_accountant"
                  placeholder="E-mail бухгалтера"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Расчетный счет IBAN</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_iban"
                  placeholder="Расчетный счет IBAN"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">БИК банковской организации</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_bik"
                  placeholder="БИК банковской организации"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="lang_type">Наименование</div>
              <div class="agreement__item--title" v-else>Наименование (на казахском)</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-if="lang_type"
                  v-model="coid_service.client_bank"
                  placeholder="Наименование  "
                  value=""
                />
                <input
                  type="text" required
                  name=""
                  v-else
                  v-model="coid_service.client_bank_kz"
                  placeholder="Наименование   (на казахском)"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">КБе</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  name=""
                  v-model="coid_service.client_kbe"
                  placeholder="КБе"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Серия свидетельство о постановке на учёт по НДС
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  v-model="coid_service.client_certificate_seria"
                  name=""
                  placeholder="серия"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Номер свидетельство о постановке на учёт по НДС
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text" required
                  v-model="coid_service.client_certificate_number"
                  name=""
                  placeholder="Номер"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">
                Дата свидетельство о постановке на учёт по НДС
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="date"
                  v-model="coid_service.client_certificate_date"
                  name=""
                  placeholder="Дата"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="line__hr" />

    <div class="row">
      <div class="col-lg-9 offset-lg-3">
        <button type="submit" class="form__button">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z"
              fill="white"
            ></path>
          </svg>
          Отправить
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from 'vue';

const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)

export default {
  props: ["lang_type"],
  components: {},
  data() {
    return {
      document: "",
      coid_service: {
        client_signer_in: "",
        client_signer_in_kz: "",
        client_fio: "",
        client_fio_kz: "",
        client_based_on: "Устава",
        client_based_on_kz: "Жарғыдан",
        client_phone_number: "",
      },
      images: [],
    };
  },
  created() {
    this.connection = new WebSocket("wss://127.0.0.1:13579/");
    this.connection.onopen = () => {
      this.ready = true;
    };
    this.connection.onmessage = (e) => {
      const arr = JSON.parse(e.data);
      const parse__arr = arr;
      this.esp__array = parse__arr;
    };
  },

  methods: {
    uploadDocument(e) {
      const file = e.target.files;
      for (var i = 0; i < file.length; i++) {
        this.images.push(file[i]);
      }
    },
    getProfile() {
      this.coid_service.client_name_company = this.GET_USER_DATA.organization.name;
      this.coid_service.client_name_company_kz = this.GET_USER_DATA.organization.name;

      this.coid_service.client_bin = this.GET_USER_DATA.organization.bin;
      this.coid_service.client_phone_number = this.GET_USER_DATA.organization.phone;
      this.coid_service.client_fio =
        this.GET_USER_DATA.common_name + " " + this.GET_USER_DATA.last_name;
      this.coid_service.client_fio_kz =
        this.GET_USER_DATA.common_name + " " + this.GET_USER_DATA.last_name;
      this.coid_service.client_signer_in = this.GET_USER_DATA.role.name_ru;
      this.coid_service.client_signer_in_kz = this.GET_USER_DATA.role.name_kz;
      this.coid_service.client_last_name = this.GET_USER_DATA.last_name;
      this.coid_service.client_last_name_kz = this.GET_USER_DATA.last_name;
      this.coid_service.client_bik = this.GET_USER_DATA.organization.bik;
    },
    createContract() {
      let contractForm = new FormData();

      for (var key in this.coid_service) {
        contractForm.append(
          "attachments[attachment_1][" + key + "]",
          this.coid_service[key]
        );
      }

      contractForm.append("attachments[attachment_2]", []);

      for (var i = 0; i < this.images.length; i++) {
        contractForm.append("documents[]", this.images[i]);
      }
      this.$axios
        .post(
          this.$API_URL + this.$API_VERSION + "user/documents/coid/notbank",
          contractForm,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.data) {
            this.$toast.open({
              message: "Успешно заполнено",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
          }
          this.$router.push("/");
        })
        .catch((error) => {
					if (error.response.data.message) {
						this.$toast.open({
              message: error.response.data.message,
              type: "error",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
					}
          this.$toast.open({
            message: "Заполните все поля на русском и казахском",
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
        });
    },
    getTypes() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "types",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.document.types = response.data.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
  mounted() {
    this.getTypes();
    this.getProfile();
  },
  watch: {},
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
};
</script>
