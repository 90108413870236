<template>
  <form @submit.prevent="updateContract()">
    <hr class="line__hr" />
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Подписант контрагента действует на основании
              </div>
              <div class="agreement__item--title" v-else>
                Подписант контрагента действует на основании (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-if="!lang_type"
                  v-model="smep.client_based_on"
                  placeholder="Подписант контрагента действует на основании"
                />
                <input
                  type="text"
                  required
                  v-else
                  v-model="smep.client_based_on_kz"
                  placeholder="Подписант контрагента действует на основании (на казахском)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Название организации
              </div>
              <div class="agreement__item--title" v-else>
                Название организации (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-if="!lang_type"
                  v-model="smep.name_organization"
                  name=""
                  placeholder="Название организации"
                />
                <input
                  type="text"
                  required
                  v-else
                  v-model="smep.name_organization_kz"
                  name=""
                  placeholder="Название организации (на казахском)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                ФИО в род. падеже
              </div>
              <div class="agreement__item--title" v-else>
                ФИО в род. падеже(на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-model="smep.client_fio"
                  name=""
                  placeholder="Подписант контрагента в лице (Должность ФИО в род. падеже)"
                  value=""
                  v-if="!lang_type"
                />
                <input
                  type="text"
                  required
                  v-model="smep.client_fio_kz"
                  name=""
                  placeholder="Подписант контрагента в лице (Должность ФИО в род. падеже) (на казахском)"
                  value=""
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="line__hr" />

    <div class="row">
      <div class="col-lg-9 offset-lg-3">
        <v-file-input
          v-model="images"
          placeholder=""
          label="Выберите файл"
          multiple
          prepend-icon="mdi-paperclip"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>

        <div class="row">
          <button type="submit" class="form__button mr-5">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z"
                fill="white"
              />
            </svg>
            Сохранить
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: ["lang_type"],
  components: {},
  data() {
    return {
      document: {
        types: [],
      },
      smep: {
        client_signer_in: "",
        client_signer_in_kz: "",
        client_fio: "",
        client_fio_kz: "",
        client_based_on: "Устава",
        client_based_on_kz: "Жарғыдан",
        client_phone_number: "",
      },
      images: [],
      messages: [],
    };
  },
  created() {
    this.connection = new WebSocket("wss://127.0.0.1:13579/");
    this.connection.onopen = () => {
      this.ready = true;
    };
    this.connection.onmessage = (e) => {
      const arr = JSON.parse(e.data);
      const parse__arr = arr;
      this.esp__array = parse__arr;
    };
  },

  methods: {
    getProfile() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "user",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.user = response.data.data;

          this.smep.client_phone_number = this.user.organization.phone;
          this.smep.name_organization = this.user.organization.name;
          this.smep.name_organization_kz = this.user.organization.name;

          this.smep.contragent = this.user.organization.name;
          this.smep.contragent_kz = this.user.organization.name;

          this.smep.client_fio = this.user.common_name + " " + this.user.last_name;
          this.smep.client_fio_kz = this.user.common_name + " " + this.user.last_name;

          this.smep.client_signer_in = this.user.role.name_ru;
          this.smep.client_signer_in_kz = this.user.role.name_ru;

          this.smep.client_last_name = this.user.last_name;
          this.smep.client_last_name_kz = this.user.last_name;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    updateContract() {
      let contractForm = new FormData();

      contractForm.append("name_organization", this.smep.name_organization);
      contractForm.append("client_fio", this.smep.client_fio);
      contractForm.append("client_last_name", this.smep.client_last_name);
      contractForm.append("client_signer_in", this.smep.client_signer_in);
      contractForm.append("client_based_on", this.smep.client_based_on);
      // contractForm.append('client_phone_number', this.smep.client_phone_number);

      contractForm.append("name_organization_kz", this.smep.name_organization_kz);
      contractForm.append("client_fio_kz", this.smep.client_fio_kz);
      contractForm.append("client_common_name_kz", this.smep.client_common_name_kz);
      contractForm.append("client_last_name_kz", this.smep.client_last_name_kz);
      contractForm.append("client_signer_in_kz", this.smep.client_signer_in_kz);
      contractForm.append("client_based_on_kz", this.smep.client_based_on_kz);

      for (var i = 0; i < this.images.length; i++) {
        contractForm.append("documents[]", this.images[i]);
      }
      this.$axios
        .post(this.$API_URL + this.$API_VERSION + "user/documents/smep", contractForm, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data) {
            this.$toast.open({
              message: "Успешно заполнено",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
          }
          this.$router.push("/");
        })
        .catch((error) => {
					if (error.response.data.message) {
						this.$toast.open({
              message: error.response.data.message,
              type: "error",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
					}
          this.$toast.open({
            message: "Заполните все поля на русском и казахском",
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          this.messages = [];
          for (let key in error.response.data.errors) {
            this.messages[key] = error.response.data.errors[key].pop();
          }
        });
    },
    getTypes() {
      this.$axios({
        method: "get",
        url: this.$API_URL + this.$API_VERSION + "types",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
        },
      })
        .then((response) => {
          this.document.types = response.data.data;
        })
        .catch((error) => {
          console.warn(error);
        });
    },
  },
  mounted() {
    this.getTypes();
    this.getProfile();
  },
  watch: {},
};
</script>
