<template>
  <form @submit.prevent="createContract()">
    <hr class="line__hr" />

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">ФИО</div>
              <div class="agreement__item--title" v-else>ФИО (на казахском)</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-if="!lang_type"
                  v-model="mspd.client_fio"
                  name=""
                  placeholder="ФИО"
                />
                <input
                  type="text"
                  required
                  v-else
                  v-model="mspd.client_fio_kz"
                  name=""
                  placeholder="ФИО (на казахском)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">
                Подписант контрагента действует на основании
              </div>
              <div class="agreement__item--title" v-else>
                Подписант контрагента действует на основании (на казахском)
              </div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-if="!lang_type"
                  v-model="mspd.client_based_on"
                  placeholder="Подписант контрагента действует на основании"
                />
                <input
                  type="text"
                  required
                  v-else
                  v-model="mspd.client_based_on_kz"
                  placeholder="Подписант контрагента действует на основании (на казахском)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title" v-if="!lang_type">Наименование</div>
              <div class="agreement__item--title" v-else>Наименование (на казахском)</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-if="!lang_type"
                  v-model="mspd.client_bank_name"
                  name=""
                  placeholder="Наименование"
                />
                <input
                  type="text"
                  required
                  v-else
                  v-model="mspd.client_bank_name_kz"
                  name=""
                  placeholder="Наименование (на казахском)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">БИК</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-model="attachment_1.bik"
                  name=""
                  placeholder="БИК"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">счет KZ</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-model="attachment_1.check_number"
                  placeholder="счет KZ"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement__line">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <div class="col-lg-3">
              <div class="agreement__item--title">Для</div>
            </div>
            <div class="col-lg-9">
              <div class="agreement__item--input">
                <input
                  type="text"
                  required
                  v-model="attachment_1.client_for"
                  name=""
                  placeholder="Для"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="line__hr" />

    <div class="row">
      <div class="col-lg-9 offset-lg-3">
        <v-file-input
          v-model="images"
          placeholder=""
          label="Выберите файл"
          multiple
          prepend-icon="mdi-paperclip"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
        <button type="submit" class="form__button">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.68 7.34667C13.7849 7.23411 13.842 7.08524 13.8393 6.93142C13.8366 6.7776 13.7742 6.63083 13.6655 6.52205C13.5567 6.41326 13.4099 6.35095 13.2561 6.34824C13.1023 6.34552 12.9534 6.40262 12.8408 6.5075L8.11458 11.2337L6.15917 9.27833C6.04661 9.17345 5.89774 9.11635 5.74392 9.11907C5.5901 9.12178 5.44334 9.1841 5.33455 9.29288C5.22576 9.40167 5.16345 9.54843 5.16074 9.70225C5.15802 9.85607 5.21512 10.0049 5.32 10.1175L7.695 12.4925C7.80633 12.6037 7.95724 12.6661 8.11458 12.6661C8.27193 12.6661 8.42284 12.6037 8.53417 12.4925L13.68 7.34667V7.34667Z"
              fill="white"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.49996 0.791666C4.69058 0.791666 0.791626 4.69062 0.791626 9.5C0.791626 14.3094 4.69058 18.2083 9.49996 18.2083C14.3093 18.2083 18.2083 14.3094 18.2083 9.5C18.2083 4.69062 14.3093 0.791666 9.49996 0.791666ZM1.97913 9.5C1.97913 7.50535 2.7715 5.5924 4.18193 4.18197C5.59236 2.77154 7.50531 1.97917 9.49996 1.97917C11.4946 1.97917 13.4076 2.77154 14.818 4.18197C16.2284 5.5924 17.0208 7.50535 17.0208 9.5C17.0208 11.4946 16.2284 13.4076 14.818 14.818C13.4076 16.2285 11.4946 17.0208 9.49996 17.0208C7.50531 17.0208 5.59236 16.2285 4.18193 14.818C2.7715 13.4076 1.97913 11.4946 1.97913 9.5V9.5Z"
              fill="white"
            ></path>
          </svg>
          Создать
        </button>
      </div>
    </div>

    <v-snackbar
      :timeout="-1"
      v-model="showToggle"
      bottom
      :color="messageColor"
      outlined
      center
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-icon :color="messageColor" v-bind="attrs" @click="showToggle = false">
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: [
    "contract_title",
    "document_id",
    "type",
    "attach_id",
    "attachment",
    "lang_type",
  ],
  data() {
    return {
      showToggle: false,
      messageColor: null,
      message: "",
      images: [],
      mspd: {
        client_based_on: "Устава",
        client_based_on_kz: "Жарғы",
        client_address: "",
        client_postcode: "",
        client_fio: "",
        client_bank_name: "",
        client_bank_name_kz: "",
        client_signer_in: "",
        client_signer_in_kz: "",
        client_fio_kz: "",
      },
      attachment_1: {
        bik: "",
        check_number: "",
        client_for: "",
      },
      messages: [],
    };
  },
  created() {},
  methods: {
    createContract() {
      let contractForm = new FormData();
      let url = "user/documents/smk";

      for (var sm_key in this.mspd) {
        contractForm.append(sm_key, this.mspd[sm_key]);
      }

      for (var key in this.attachment_1) {
        contractForm.append(
          "attachments[attachment_1][" + key + "]",
          this.attachment_1[key]
        );
      }

      for (var i = 0; i < this.images.length; i++) {
        contractForm.append("documents[]", this.images[i]);
      }
      this.$axios
        .post(this.$API_URL + this.$API_VERSION + url, contractForm, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$toast.open({
            message: "Успешно сохранено",
            type: "success",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
          this.$router.go(-1);
        })
        .catch((error) => {
				
         
          if (error.response && error.response.status == 422) {
            this.messages = [];
            for (let key in error.response.data.errors) {
              this.messages[key] = error.response.data.errors[key].pop();
            }
          } else if (error.response.data.message) {
						this.$toast.open({
              message: error.response.data.message,
              type: "error",
              position: "bottom",
              duration: 4000,
              queue: true,
            });
					}
							
          this.$toast.open({
            message: "Заполните все поля на русском и казахском",
            type: "error",
            position: "bottom",
            duration: 4000,
            queue: true,
          });
        });
    },
  },
  mounted() {
    this.mspd.client_bank_name = this.GET_USER_DATA.organization.name;
    this.mspd.client_fio =
      this.GET_USER_DATA.common_name + " " + this.GET_USER_DATA.last_name;
    this.mspd.client_fio_kz =
      this.GET_USER_DATA.common_name + " " + this.GET_USER_DATA.last_name;
    this.mspd.client_signer_in = this.GET_USER_DATA.role.name_ru;
    this.mspd.client_signer_in_kz = this.GET_USER_DATA.role.name_kz;

    this.attachment_1.client_for = this.GET_USER_DATA.organization.name;
    this.attachment_1.bik = this.GET_USER_DATA.organization.bik;
  },
  watch: {},
  computed: {
    ...mapGetters(["GET_TOKEN", "GET_USER_DATA"]),
  },
};
</script>
